import Decimal from 'decimal.js'
import axios from 'axios'

function plusTwo(num, addNum = 2) {
  if (!num) return '--';

  let val = '';
  try {
    val = `${Decimal(Number(num.toString().replace('%', '')))
      .add(addNum)
      .toString()}%`;
  } catch (e) {
    return '--';
  }

  return val;
}

function returnHkUsMinMaxRate(data = {}) {
  const usList = data.USD;
  const hkList = data.HKD;
  const cnList = data.CNH;
  if (usList && hkList && cnList) {
    const usStr = usList[usList.length - 1].rate;
    const usHighStr = usList[0].rate;


    const hkStr = hkList[hkList.length - 1].rate;
    const hkHighStr = hkList[0].rate;

    const cnStr = cnList[cnList.length - 1].rate;
    const cnHighStr = cnList[0].rate;

    return {
      hkStr,
      hkHighStr,
      usStr,
      usHighStr,
      cnStr,
      cnHighStr,
    };
  }

  return null;
}

function getHkUsMinMaxRate(data = {}) {
  const hkUsMinMaxRate = returnHkUsMinMaxRate(data);

  if (hkUsMinMaxRate === null) return {};

  const {
    hkStr,
    hkHighStr,
    usStr,
    usHighStr,
  } = hkUsMinMaxRate;

  return {
    hkRate: hkStr,
    hkHighRate: hkHighStr,
    usRate: usStr,
    usHighRate: usHighStr,
  };
}

function getHkUsMinMaxRatePlusTwo(data = {}) {
  const hkUsMinMaxRate = returnHkUsMinMaxRate(data);

  if (hkUsMinMaxRate === null) return null;

  const {
    hkStr,
    hkHighStr,
    usStr,
    usHighStr,
    cnStr,
    cnHighStr,
  } = hkUsMinMaxRate;

  return {
    hkRate: plusTwo(hkStr),
    hkHighRate: plusTwo(hkHighStr),
    usRate: plusTwo(usStr),
    usHighRate: plusTwo(usHighStr),
    cnRate: plusTwo(cnStr, 1),
    cnHighRate: plusTwo(cnHighStr, 1),
  };
}

function getMarginInterest() {
  return axios.get('/xyapi/fd/query/margin-interest', {
    params: {
      lang: 'EN',
    },
  }).then((res) => {
    if (res && res.data && res.data.result_data) {
      const data = res.data.result_data;
      return data;
    }
    return Promise.reject();
  }).catch(() => {
    return Promise.reject();
  })
  ;
}

export {
  plusTwo,
  getHkUsMinMaxRate,
  getHkUsMinMaxRatePlusTwo,
  getMarginInterest,
}