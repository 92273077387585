import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {graphql, Link} from 'gatsby'

import '../../pricing-pages/styles.scss'
import Layout from '../../../components/Layout'
import Content, {HTMLContent} from '../../../components/Content'
import SharedJumbotron from '../../../components/SharedJumbotron'
import DownloadNow from '../../../components/DownloadNow'

import { getMarginInterest, plusTwo } from '../../../common';

type FinancingInterestRatesPageProps = {
  content: Node.isRequired,
  contentComponent: any,
  downloadNow: {
    mainText: string,
    subText: string,
    image: any,
  },
  headerImage: any,
}

export const FinancingInterestRatesPageTemplate = (props: FinancingInterestRatesPageProps) => {
  const PostContent = props.contentComponent || Content
  const {content, headerImage, downloadNow} = props

  const [usdRate, setUsdRate] = useState([])

  const [hkdRate, setHkdRate] = useState([])

  useEffect(() => {
    getMarginInterest().then(data => {
      if (data.HKD) {
        setHkdRate(data.HKD);
      }
  
      if (data.USD) {
        setUsdRate(data.USD);
      }
    }).catch(err => {
      console.log(err)
    })
  }, []);

  return (
    <div className="pricing-pages">
      <SharedJumbotron headerImage={headerImage} />

      <section className="blog-post-container" style={{paddingBottom: '250px'}}>
        <div className="container content">
          <div className="row justify-content-center">
            <div className="col col-10 blog-container">
              <h3>
                FINANCING INTEREST RATES
              </h3>
              {/* <PostContent content={content} /> */}
              <p>
                With Snowball X, the interest rate on margin loan is generally based on tiers of the amount borrowed. The greater amount borrowed, the lower interest rate applied. Interest is calculated on a daily basis, charged on a monthly basis. There is no interest incurred if the loan is repaid on the same day that it is drawn.
                <br />
              </p>
              <p>
                The interest formula:
                <br />
                US Dollar: interest = amount borrowed x (R/360).
                <br />
                HK Dollar: interest = amount borrowed x (R/365).
                <br />
                CHN: Interest = amount borrowed x (R/365).
              </p>
              <p>
                Where R is the annual interest rate prevailing on the day the fund is borrowed. A balance of interest will be sent to client on the third working day each month, which is the sum of daily interest incurred in the previous month. Clients can check balance of the interest, and around 5th each month, the balance is added to the liability of clients.
                <br />
                <br />
              </p>
              <h5>
                Financing interest rate for US Dollar
              </h5>
              <div className="table-responsive">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">Currency</th>
                      <th scope="col">Loan amount</th>
                      <th scope="col">Interest rate</th>
                    </tr>
                  </thead>
                  <tr>
                    <td rowspan="5">USD</td>
                    <td>0 - 100,000</td>
                    <td>
                      {plusTwo(usdRate[0] && usdRate[0].rate)}
                    </td>
                  </tr>
                  <tr>
                    <td>100,000.01 - 1,000,000</td>
                    <td>{plusTwo(usdRate[1] && usdRate[1].rate)}</td>
                  </tr>
                  <tr>
                    <td>1,000,000.01 - 3,000,000</td>
                    <td>{plusTwo(usdRate[2] && usdRate[2].rate)}</td>
                  </tr>
                  <tr>
                    <td>3,000,000.01 – 200,000,000</td>
                    <td>{plusTwo(usdRate[3] && usdRate[3].rate)}</td>
                  </tr>
                  <tr>
                    <td>200,000,000.01 +</td>
                    <td>{plusTwo(usdRate[4] && usdRate[4].rate)}</td>
                  </tr>
                </table>
              </div>
              <h5>
                <br/><br/>Financing interest rate for HK Dollar
              </h5>
              <div className="table-responsive">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">Currency</th>
                      <th scope="col">Loan amount</th>
                      <th scope="col">Interest rate</th>
                    </tr>
                  </thead>
                  <tr>
                    <td rowspan="4">HKD</td>
                    <td>0 - 780,000</td>
                    <td>{plusTwo(hkdRate[0] && hkdRate[0].rate)}</td>
                  </tr>
                  <tr>
                    <td>780,000.01 - 7,800,000</td>
                    <td>{plusTwo(hkdRate[1] && hkdRate[1].rate)}</td>
                  </tr>
                  <tr>
                    <td>7,800,000.01 - 780,000,000</td>
                    <td>{plusTwo(hkdRate[2] && hkdRate[2].rate)}</td>
                  </tr>
                  <tr>
                    <td>780,000,000.01 +</td>
                    <td>{plusTwo(hkdRate[3] && hkdRate[3].rate)}</td>
                  </tr>
                </table>
              </div>
              <p>
                Notes: <br/>The financing interest rate is subject to change. Please check the latest rate on the prices page.<br/>The tiered commission models on which interest is calculated based may change from time to time without prior notice to the customer. These adjustments are cyclical to accommodate changes in currency exchange rates.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

const FinancingInterestRatesPage = ({data}) => {
  const {markdownRemark: post} = data

  return (
    <Layout footerLinks={post.frontmatter.footerLinks}>
      <FinancingInterestRatesPageTemplate
        content={post.html}
        contentComponent={HTMLContent}
        downloadNow={post.frontmatter.downloadNow}
        headerImage={post.frontmatter.headerImage}
      />
    </Layout>
  )
}

FinancingInterestRatesPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default FinancingInterestRatesPage

export const pageQuery = graphql`
  query FinancingInterestRates($id: String!) {
    markdownRemark(id: {eq: $id}) {
      id
      html
      frontmatter {
        headerImage {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        downloadNow {
          mainText
          subText
          image {
            childImageSharp {
              fluid(maxWidth: 1024, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        footerLinks {
          firstRow {
            name
            href
          }
          secondRow {
            name
            href
          }
        }
      }
    }
  }
`
